'use client'

import { Button, InputText, Spacer, Text, Validation } from '@vinted/web-ui'
import { useContext, useEffect } from 'react'

import { useAbTest, useTrackAbTest } from '@marketplace-web/shared/ab-tests'
import { navigateToPage, useLocation } from '@marketplace-web/shared/browser'
import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { UiState } from '@marketplace-web/shared/ui-helpers'
import { urlWithParams } from '@marketplace-web/shared/utils'

import { clickEvent, viewScreenEvent } from '_libs/common/event-tracker/events'
import FaqEntryUrl from 'components/FaqEntryUrl'
import { FaqEntryType } from 'constants/faq-entry'
import { EMAIL_CODE_VERIFICATION_URL, ROOT_URL } from 'constants/routes'
import { ClickableElement } from 'constants/tracking/clickable-elements'
import { Screen } from 'constants/tracking/screens'
import { ResponseCode } from 'data/api/response-codes'
import useRefUrl from 'hooks/useRefUrl'

import { EmailCodeView } from '../../../constants'
import { MIN_CODE_LENGTH } from '../constants'
import Context from '../EmailVerificationCodeContext'
import useEnterCodeForm from '../hooks/useEnterCodeForm'

const EnterCode = () => {
  const translate = useTranslate()
  const { track } = useTracking()
  const { formError, resetForm, handleSubmit, register, isLoading, watch, isCodeValidated } =
    useEnterCodeForm()
  const { email, sendEmailCode, uiState, resetUiState } = useContext(Context)
  const { pushHistoryState } = useLocation()
  const refUrl = useRefUrl()

  const isSendingCode = uiState === UiState.Pending
  const isExpiredCode = formError?.code === ResponseCode.EmailVerificationCodeExpired

  const emailVerificationCopy1Abtest = useAbTest('email_verification_copy_1_all')
  useTrackAbTest(emailVerificationCopy1Abtest)

  const isEmailVerificationCopy1AbtestOn =
    emailVerificationCopy1Abtest && emailVerificationCopy1Abtest.variant !== 'off'

  const emailVerificationCopy2Abtest = useAbTest('email_verification_copy_2_all')
  useTrackAbTest(emailVerificationCopy2Abtest)

  const isEmailVerificationCopy2AbtestOn =
    emailVerificationCopy2Abtest && emailVerificationCopy2Abtest.variant !== 'off'

  const emailVerificationCopy3Abtest = useAbTest('email_verification_copy_3_all')
  useTrackAbTest(emailVerificationCopy3Abtest)

  const isEmailVerificationCopy3AbtestOn =
    emailVerificationCopy3Abtest && emailVerificationCopy3Abtest.variant === 'on'

  useEffect(() => {
    track(viewScreenEvent({ screen: Screen.MandatoryEmailVerificationEnterCode }))
  }, [track])

  useEffect(() => {
    if (!isCodeValidated) return

    navigateToPage(urlWithParams(ROOT_URL, { ref_url: refUrl }))
  }, [isCodeValidated, refUrl])

  const handleFaqClick = url => {
    track(
      clickEvent({
        screen: Screen.MandatoryEmailVerificationStart,
        target: ClickableElement.Help,
      }),
    )

    resetUiState()
    navigateToPage(urlWithParams(url, { refUrl }))
  }

  const handleHelpClick = () => {
    track(
      clickEvent({
        screen: Screen.MandatoryEmailVerificationEnterCode,
        target: ClickableElement.DidntReceiveEmail,
      }),
    )

    resetUiState()
    pushHistoryState(
      urlWithParams(EMAIL_CODE_VERIFICATION_URL, {
        view: EmailCodeView.NotReceiveEmail,
        ref_url: refUrl,
      }),
    )
  }

  const handleResend = (event: React.BaseSyntheticEvent) => {
    event.preventDefault()

    resetForm()
    sendEmailCode()
  }

  const renderTitle = () => {
    return (
      <Text as="h1" type={Text.Type.Heading}>
        {translate('email_verification_code.enter_code.title')}
      </Text>
    )
  }

  const renderDescription = () => {
    const descriptionTranslationKey = isEmailVerificationCopy1AbtestOn
      ? `email_verification_code.enter_code.description_abtest_${emailVerificationCopy1Abtest.variant}`
      : 'email_verification_code.enter_code.description'

    return (
      <>
        <Text as="span" type={Text.Type.Body} theme="muted">
          {translate(descriptionTranslationKey)}
        </Text>
        <Text as="span" type={Text.Type.Body} theme="amplified">
          {email}
        </Text>
      </>
    )
  }

  const renderInput = () => {
    const placeholderTranslationKey = isEmailVerificationCopy2AbtestOn
      ? `email_verification_code.enter_code.inputs.code.placeholder_abtest_${emailVerificationCopy2Abtest.variant}`
      : 'email_verification_code.enter_code.inputs.code.placeholder'

    return (
      <>
        <InputText
          {...register}
          type={InputText.Type.Text}
          inputMode={InputText.InputMode.Numeric}
          placeholder={translate(placeholderTranslationKey)}
          styling={InputText.Styling.Tight}
          disabled={isSendingCode}
          validation={<Validation text={formError?.message} theme="warning" />}
        />
        {!formError?.message && <Spacer size={Spacer.Size.Large} />}
      </>
    )
  }

  const renderActionButton = () => {
    const buttonText = isExpiredCode
      ? translate('email_verification_code.enter_code.actions.resend')
      : translate('email_verification_code.enter_code.actions.submit')

    return (
      <Button
        testId="verification-code-submit-button"
        type={Button.Type.Submit}
        styling={Button.Styling.Filled}
        disabled={isLoading || String(watch().code).length !== MIN_CODE_LENGTH}
        isLoading={isLoading || isSendingCode}
        onClick={(event: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => {
          if (isLoading || isSendingCode) {
            event.preventDefault()
          }
        }}
      >
        {buttonText}
      </Button>
    )
  }

  const renderHelpButton = () => {
    const helpButtonTextTranslationKey = isEmailVerificationCopy3AbtestOn
      ? 'email_verification_code.enter_code.actions.help_abtest_on'
      : 'email_verification_code.enter_code.actions.help'

    return (
      <>
        <Button
          onClick={handleHelpClick}
          styling={Button.Styling.Flat}
          disabled={isLoading || isSendingCode}
        >
          {translate(helpButtonTextTranslationKey)}
        </Button>
        <FaqEntryUrl
          type={FaqEntryType.HowToVerifyEmailCode}
          render={url => (
            <Button styling={Button.Styling.Flat} onClick={() => handleFaqClick(url)}>
              {translate('email_verification_code.enter_code.actions.have_any_questions')}
            </Button>
          )}
        />
      </>
    )
  }

  const handleSubmitClick = (event: React.BaseSyntheticEvent) => {
    if (isExpiredCode) {
      handleResend(event)

      return
    }

    handleSubmit(event)
  }

  return (
    <form onSubmit={handleSubmitClick}>
      {renderTitle()}
      <Spacer size={Spacer.Size.Medium} />
      {renderDescription()}
      <Spacer size={Spacer.Size.X3Large} />
      {renderInput()}
      <Spacer size={Spacer.Size.Large} />
      {renderActionButton()}
      <Spacer size={Spacer.Size.Medium} />
      {renderHelpButton()}
    </form>
  )
}

export default EnterCode
