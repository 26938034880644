import { withServerSideProxy } from '@marketplace-web/shared/ssr'

export const { preventLeave } = withServerSideProxy(
  {
    preventLeave: (unloadEvent: BeforeUnloadEvent) => {
      unloadEvent.preventDefault()

      // Workaround for chrome not supporting the above recommended way, for more details see https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
      unloadEvent.returnValue = true // eslint-disable-line no-param-reassign

      return true
    },
  },
  {
    preventLeave: () => true,
  },
)
