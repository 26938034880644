'use client'

import { ChangeEvent, useState } from 'react'
import { Button, Cell, Spacer, Text, Validation } from '@vinted/web-ui'

import { useSession } from '@marketplace-web/shared/session'
import { DeprecatedInputText } from '@marketplace-web/shared/ui-helpers'
import useRefUrl from 'hooks/useRefUrl'
import { useTranslate } from '@marketplace-web/shared/i18n'

import { SECURITY_SETTINGS_URL } from 'constants/routes'
import { changeExistingEmail } from 'data/api'

const EmailVerification = () => {
  const refUrl = useRefUrl(SECURITY_SETTINGS_URL)
  const translate = useTranslate('email_verification')
  const { user } = useSession()
  const userEmail = user?.email
  const userId = user?.id

  const [isVerificationComplete, setIsVerificationComplete] = useState(false)
  const [email, setEmail] = useState(userEmail)
  const [error, setError] = useState<null | string>(null)

  const handleSendClick = async () => {
    if (!userId || !email) return

    const response = await changeExistingEmail({ userId, email })

    if ('errors' in response) {
      setError(response.errors[0]?.value || response.message)

      return
    }

    setIsVerificationComplete(true)
  }

  const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const renderValidationMessage = () => {
    if (!error) return null

    return <Validation text={error} theme="warning" />
  }

  return (
    <div className="narrow-container u-margin-auto">
      <Cell>
        <Text
          as="h1"
          width={Text.Width.Parent}
          alignment={Text.Alignment.Center}
          type={Text.Type.Heading}
          text={translate('title')}
        />
        <Spacer size={Spacer.Size.XLarge} />
        <Text
          as="span"
          text={isVerificationComplete ? translate('waiting_for_confirmation') : translate('body')}
        />
        {!isVerificationComplete && (
          <DeprecatedInputText
            name="email"
            title={translate('input_label')}
            value={email}
            onChange={handleEmailChange}
            validation={renderValidationMessage()}
          />
        )}
        {isVerificationComplete ? (
          <>
            <Spacer size={Spacer.Size.Large} />
            <Button text={translate('actions.done')} styling={Button.Styling.Filled} url={refUrl} />
          </>
        ) : (
          <Button
            text={translate('actions.send')}
            styling={Button.Styling.Filled}
            onClick={handleSendClick}
          />
        )}
      </Cell>
    </div>
  )
}

export default EmailVerification
