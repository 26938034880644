'use client'

import { useMemo, useState } from 'react'

import { useWindowEvent } from '@marketplace-web/shared/browser'

import { DataDomeContext } from './DataDomeContext'

type Props = {
  children: React.ReactNode
}

const DataDomeProvider = ({ children }: Props) => {
  const [isDataDomeScriptReady, setIsDataDomeScriptReady] = useState(false)

  useWindowEvent('dd_ready', () => {
    setIsDataDomeScriptReady(true)
  })

  const contextValue = useMemo(
    () => ({
      isDataDomeScriptReady,
    }),
    [isDataDomeScriptReady],
  )

  return <DataDomeContext.Provider value={contextValue}>{children}</DataDomeContext.Provider>
}

export default DataDomeProvider
