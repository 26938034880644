'use client'

import { Button, Cell, Dialog, Spacer, Text } from '@vinted/web-ui'

import { useTranslate } from '@marketplace-web/shared/i18n'

import { Submit } from '../types'

type Props = {
  onConfirm: (args?: Submit) => void
  onClose: () => void
  email: string
}

const ConfirmationModal = ({ email, onConfirm, onClose }: Props) => {
  const translate = useTranslate()

  const handleConfirm = () => {
    onConfirm()
  }

  const handleCancel = () => {
    onConfirm({ useOldEmail: true })
  }

  const renderTitle = () => (
    <Text
      as="h1"
      type={Text.Type.Heading}
      alignment={Text.Alignment.Center}
      width={Text.Width.Parent}
    >
      {translate('email_verification_code.not_receive_email.confirmation_modal.title')}
    </Text>
  )

  const renderDescription = () => (
    <Text as="span" alignment={Text.Alignment.Center} width={Text.Width.Parent} theme="muted">
      {translate('email_verification_code.not_receive_email.confirmation_modal.description')}
      <br />
      <Text as="span" bold theme="muted">
        {email}
      </Text>
    </Text>
  )

  const renderButtons = () => (
    <>
      <Button styling={Button.Styling.Filled} onClick={handleConfirm}>
        {translate('email_verification_code.not_receive_email.confirmation_modal.actions.submit')}
      </Button>
      <Spacer size={Spacer.Size.Medium} />
      <Button styling={Button.Styling.Flat} onClick={handleCancel}>
        {translate('email_verification_code.not_receive_email.confirmation_modal.actions.cancel')}
      </Button>
    </>
  )

  return (
    <Dialog
      show
      className="auth__container"
      testId="not-receive-email--confirmation-modal"
      closeOnOverlay
      defaultCallback={onClose}
    >
      <Cell>
        {renderTitle()}
        <Spacer size={Spacer.Size.Large} />
        {renderDescription()}
        <Spacer size={Spacer.Size.XLarge} />
        {renderButtons()}
      </Cell>
    </Dialog>
  )
}

export default ConfirmationModal
