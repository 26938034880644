'use client'

import { useCallback, useEffect, useRef } from 'react'

type Options = { once?: boolean }

export default function useWindowEvent<T extends keyof WindowEventMap>(
  eventName: T,
  handler: (event: WindowEventMap[T]) => void,
  options?: Options,
) {
  const handlerRef = useRef(handler)
  handlerRef.current = handler
  const optionsRef = useRef(options)
  optionsRef.current = options

  const handleEvent = useCallback((event: WindowEventMap[T]) => {
    handlerRef.current(event)
  }, [])

  useEffect(() => {
    window.addEventListener(eventName, handleEvent, optionsRef.current)

    return () => window.removeEventListener(eventName, handleEvent)
  }, [eventName, handleEvent])
}
