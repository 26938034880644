'use client'

import { Fragment, useEffect, useState } from 'react'
import { Button, Card, Cell, Spacer, Text } from '@vinted/web-ui'

import { navigateToPage, useLocation } from '@marketplace-web/shared/browser'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useSession } from '@marketplace-web/shared/session'
import { urlWithParams } from '@marketplace-web/shared/utils'

import { FlashMessageType, setFlashMessage } from '_libs/utils/flash-message'
import FaqEntryUrl from 'components/FaqEntryUrl'
import { FaqEntryType } from 'constants/faq-entry'
import { EMAIL_VERIFICATION_URL, PHONE_VERIFICATION_URL, ROOT_URL } from 'constants/routes'
import { VerificationMethods, VerificationPromptCategory } from 'constants/verification'
import { dismissVerificationPrompt, getVerificationPrompt } from 'data/api'
import { transformVerificationPromptDto } from 'data/transformers/verification-prompt'
import useRefUrl from 'hooks/useRefUrl'
import { VerificationPromptModel } from 'types/models/verification-prompt'

import EmailVerificationCode from '../EmailVerificationCode/EmailVerificationCode'
import GoogleVerification from './GoogleVerification'

const VerificationPrompt = () => {
  const userId = useSession().user?.id
  const translate = useTranslate('verification_prompt')
  const [promptDetails, setPromptDetails] = useState<VerificationPromptModel>()
  const [error, setError] = useState<null | string>(null)
  const refUrl = useRefUrl(ROOT_URL)
  const { searchParams } = useLocation()

  useEffect(() => {
    const fetchPromptDetails = async () => {
      if (!userId) return

      const response = await getVerificationPrompt(userId)

      if ('errors' in response) return

      const transformedVerificationPrompt = transformVerificationPromptDto(response.prompt)
      setPromptDetails(transformedVerificationPrompt)
    }

    fetchPromptDetails()
  }, [userId])

  const handleDismissClick = async () => {
    if (!userId) return

    await dismissVerificationPrompt(userId)
    navigateToPage(refUrl)
  }

  const handleGoogleSuccess = () => {
    navigateToPage(refUrl)
  }

  const handleGoogleError = (errorMessage: string) => {
    setError(errorMessage)
  }

  if (!promptDetails) return null

  if (!promptDetails.methods.length) {
    setFlashMessage({ type: FlashMessageType.Success, message: translate('already_verified') })
    navigateToPage(refUrl)

    return null
  }

  const renderPhoneVerificationAction = () => {
    return (
      <>
        <Button
          text={translate('phone_verification_button')}
          url={urlWithParams(PHONE_VERIFICATION_URL, searchParams)}
          styling={Button.Styling.Filled}
        />
        {promptDetails.category === VerificationPromptCategory.GoodActor && (
          <>
            <Spacer size={Spacer.Size.Large} />
            <FaqEntryUrl
              type={FaqEntryType.PhoneVerification}
              render={url => (
                <Button
                  url={url}
                  text={translate('learn_more')}
                  styling={Button.Styling.Flat}
                  urlProps={{ target: '_blank' }}
                />
              )}
            />
          </>
        )}
      </>
    )
  }

  const renderEmailVerificationAction = () => (
    <Button
      text={translate('email_verification_button')}
      url={EMAIL_VERIFICATION_URL}
      styling={Button.Styling.Filled}
    />
  )

  const renderGoogleVerificationAction = () => (
    <GoogleVerification onSuccess={handleGoogleSuccess} onError={handleGoogleError} />
  )

  const renderVerificationOption = (verificationMethod: VerificationMethods) => {
    switch (verificationMethod) {
      case VerificationMethods.Phone:
        return renderPhoneVerificationAction()
      case VerificationMethods.Google:
        return renderGoogleVerificationAction()
      default:
        return renderEmailVerificationAction()
    }
  }

  const renderError = () => {
    if (!error) return null

    return (
      <>
        <Text as="span" text={error} theme="warning" />
        <Spacer />
      </>
    )
  }

  const renderActions = () => (
    <>
      {promptDetails.methods.map(method => (
        <Fragment key={method}>
          <Spacer size={Spacer.Size.Large} />
          {renderVerificationOption(method)}
        </Fragment>
      ))}
      {!promptDetails.mandatory && (
        <>
          <Spacer size={Spacer.Size.Large} />
          <Button
            text={translate('dismiss_button')}
            onClick={handleDismissClick}
            styling={Button.Styling.Flat}
          />
        </>
      )}
    </>
  )

  const renderBody = () => (
    <>
      <Spacer size={Spacer.Size.XLarge} />
      {renderError()}
      <Text as="span" text={promptDetails?.translations.body} />
      {renderActions()}
    </>
  )

  const renderContent = () => {
    if (promptDetails.methods.includes(VerificationMethods.EmailCode)) {
      return <EmailVerificationCode />
    }

    return (
      <>
        <Spacer size={Spacer.Size.XLarge} />
        <Card>
          <Spacer size={Spacer.Size.Large} />
          <Cell
            title={
              <Text
                as="h1"
                text={promptDetails.translations.header}
                type={Text.Type.Heading}
                alignment={Text.Alignment.Center}
                width={Text.Width.Parent}
              />
            }
            body={renderBody()}
          />
        </Card>
      </>
    )
  }

  return <div className="auth__container">{renderContent()}</div>
}

export default VerificationPrompt
