import { DataDomeProvider } from '@marketplace-web/domain/data-dome'

import EmailVerificationCodeProvider from './EmailVerificationCodeProvider'
import Content from './Content'

const EmailVerificationCode = () => (
  <DataDomeProvider>
    <EmailVerificationCodeProvider>
      <Content />
    </EmailVerificationCodeProvider>
  </DataDomeProvider>
)

export default EmailVerificationCode
