'use client'

import { useContext } from 'react'
import { Card, Cell, Icon, Spacer } from '@vinted/web-ui'
import { WarningCircleFilled24 } from '@vinted/monochrome-icons'

import { useBreakpoint } from '@marketplace-web/shared/breakpoints'
import { useLocation } from '@marketplace-web/shared/browser'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { HttpStatus } from 'data/api/response-codes'

import { EmailCodeView } from '../../constants'
import Context from './EmailVerificationCodeContext'
import EnterCode from './EnterCode'
import NotReceiveEmail from './NotReceiveEmail'

const componentByEmailCodeView: Record<EmailCodeView, () => JSX.Element> = {
  [EmailCodeView.EnterCode]: EnterCode,
  [EmailCodeView.NotReceiveEmail]: NotReceiveEmail,
}

const Content = () => {
  const breakpoint = useBreakpoint()
  const translate = useTranslate()
  const { error } = useContext(Context)
  const { view: viewParam } = useLocation().searchParams
  const view = typeof viewParam === 'string' ? viewParam : EmailCodeView.EnterCode

  const isTooManyRequestsError = error?.status === HttpStatus.TooManyRequests
  const isPhonesOrTablets = breakpoint.tablets || breakpoint.phones
  const Component = componentByEmailCodeView[view]

  const renderContent = () => {
    if (isPhonesOrTablets) {
      return (
        <>
          {!isTooManyRequestsError && <Spacer size={Spacer.Size.Regular} />}
          <Component />
        </>
      )
    }

    return (
      <>
        {!isTooManyRequestsError && <Spacer size={Spacer.Size.X4Large} />}
        <Component />
        <Spacer size={Spacer.Size.X5Large} />
      </>
    )
  }

  const renderErrorMessage = () => {
    if (!isTooManyRequestsError) return null

    return (
      <>
        <Card>
          <Cell
            prefix={<Icon name={WarningCircleFilled24} color={Icon.Color.Warning} />}
            body={translate('email_verification_code.errors.too_many_requests.body')}
          />
        </Card>
        <Spacer size={Spacer.Size.XLarge} />
      </>
    )
  }

  return (
    <>
      {renderErrorMessage()}
      <div className="auth__container">{renderContent()}</div>
    </>
  )
}

export default Content
