'use client'

import { useEffect } from 'react'
import { noop } from 'lodash'

import { preventLeave } from '../utils/event'

export default function usePreventWindowUnload(
  preventDefault: boolean | (() => boolean),
  callback: () => void = noop,
) {
  useEffect(() => {
    function handleBeforeUnload(event: BeforeUnloadEvent) {
      const finalPreventDefault = typeof preventDefault === 'function' ? preventDefault() : true

      if (!finalPreventDefault) return

      callback()
      preventLeave(event)
    }

    if (!preventDefault) return undefined

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => window.removeEventListener('beforeunload', handleBeforeUnload)
  }, [preventDefault, callback])
}
