import { Response } from '@marketplace-web/shared/api-client'
import { SendEmailVerificationCodeResp } from 'types/api/response/send-email-verification-code-resp'
import { EmailVerificationCodeModel } from 'types/models/email-verification-code'

export const transformEmailVerificationCodeResponse = (
  response: Response<SendEmailVerificationCodeResp>,
): EmailVerificationCodeModel => ({
  email: response.email,
  canChangeEmail: response.can_change_email ?? true,
})
