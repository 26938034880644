export enum VerificationPromptCategory {
  GoodActor = 'good_actor',
  BadActor = 'bad_actor',
}

export enum VerificationMethods {
  Email = 'email',
  Phone = 'phone',
  EmailCode = 'email_code',
  Google = 'google',
}

export enum VerificationActions {
  BalanceWithdrawal = 'balance_withdrawal',
  AccountDeletion = 'account_deletion',
  DataExport = 'data_export',
}

export enum VerificationTrigger {
  ApRegistration = 'ap_registration',
}
