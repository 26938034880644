'use client'

import { Button, Image, Spacer, Text } from '@vinted/web-ui'
import { useEffect } from 'react'

import { useTracking } from '@marketplace-web/shared/event-tracker'
import { useTranslate } from '@marketplace-web/shared/i18n'
import { useAsset } from '@marketplace-web/shared/ui-helpers'

import { viewScreenEvent } from '_libs/common/event-tracker/events'
import { ROOT_URL } from 'constants/routes'
import { Screen } from 'constants/tracking/screens'
import useRefUrl from 'hooks/useRefUrl'

const SuccessEmailCodeVerification = () => {
  const refUrl = useRefUrl(ROOT_URL)
  const translate = useTranslate()
  const { track } = useTracking()
  const asset = useAsset('/assets/verifications/email-verification-code')

  useEffect(() => {
    track(viewScreenEvent({ screen: Screen.MandatoryEmailVerificationSuccess }))
  }, [track])

  const renderImage = () => {
    const imgSrc = asset('success.png')

    return (
      <div className="u-text-center">
        <Image
          src={imgSrc}
          srcset={`${imgSrc} 1x, ${asset('success@2x.png')} 2x, ${asset('success@3x.png')} 3x`}
        />
      </div>
    )
  }

  const renderTitle = () => {
    return (
      <Text
        as="h1"
        type={Text.Type.Heading}
        alignment={Text.Alignment.Center}
        width={Text.Width.Parent}
      >
        {translate('email_verification_code.success.title')}
      </Text>
    )
  }

  const renderDescription = () => {
    return (
      <Text
        as="span"
        type={Text.Type.Body}
        alignment={Text.Alignment.Center}
        width={Text.Width.Parent}
      >
        {translate('email_verification_code.success.description')}
      </Text>
    )
  }

  const renderButton = () => {
    return (
      <Button testId="start-button" styling={Button.Styling.Filled} url={refUrl}>
        {translate('email_verification_code.success.actions.ok')}
      </Button>
    )
  }

  return (
    <div className="auth__container">
      {renderImage()}
      <Spacer size={Spacer.Size.XLarge} />
      {renderTitle()}
      <Spacer size={Spacer.Size.Medium} />
      {renderDescription()}
      <Spacer size={Spacer.Size.X2Large} />
      {renderButton()}
    </div>
  )
}

export default SuccessEmailCodeVerification
